declare const env: any;

export const environment = {
  production: true,
  tag: 'prod',
  appVersion: require('../../package.json').version,
  apiURL: 'https://api.alerta.alertalicita.com',
  allowedDomains: [
    'api.alerta.alertalicita.com'
  ],
  blacklistedRoutes: []
};
